<template>
  <v-btn
    v-if="!(column.options && column.options.hide && column.options.hide(value))"
    icon
    :color="column.options.color"
    :disabled="column.options && column.options.disabled && column.options.disabled(value)"
    @click.stop="column.options.fn(column.options.useItem ? value : itemId)"
  >
    <v-icon>{{$icon(column.options.icon)}}</v-icon>
  </v-btn>
</template>

<script>
// Required import as prop validators are not in the scope
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [Object, String]
    },
    itemId: {
      type: [String]
    },
    column: Column
  }
}
</script>
